import React from 'react';
import Logo from 'components/icons/logo';
import { useAuth0 } from '@auth0/auth0-react';

const Unauthorized = ({ title, description }) => {

    const { user, logout } = useAuth0();

    return (
        <div className="flex h-screen items-center justify-center">
            <div className="absolute top-12">
                <Logo />
            </div>
            <div className="flex flex-col items-center">
                <h1 className="text-2xl font-medium pb-3">{title}</h1>
                <span>{description}</span>
                <button className="font-medium" onClick={(e) => { e.preventDefault(); logout({returnTo: window.location.origin}); }}>Logout</button>
            </div>
        </div>);
};

export default Unauthorized;