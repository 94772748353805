import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from "react";
import { getUnreadNotifications, markAllNotificationsAsRead, markNotificationAsRead, markNotificationAsUnread, markPendingNotificationsAsUnread } from 'api/admin';

export const NotificationsContext = React.createContext({});

const useNotifications = () => useContext(NotificationsContext);
export default useNotifications;

export const NotificationsProvider = ({children}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isNotificationsLoading, setIsNotificationsLoading] = useState();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const load = async () => {
            try {
                var token = await getAccessTokenSilently();
                await resetNotifications(token);
            } catch (error) {
                console.error(error);
            }

            setIsNotificationsLoading(false);
        };

        setIsNotificationsLoading(true);
        load();
    }, [getAccessTokenSilently])

    const markPendingAsUnread = async (notificationTypeId) => {
        try {
            var token = await getAccessTokenSilently();
            const result = await markPendingNotificationsAsUnread(token, notificationTypeId);

            if (!result.error) {
                await resetNotifications(token);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const markAllAsRead = async (notificationTypeId) => {
        try {
            var token = await getAccessTokenSilently();
            const result = await markAllNotificationsAsRead(token, notificationTypeId);

            if (!result.error) {
                await resetNotifications(token);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const markAsRead = async (notification) => {
        try {
            var token = await getAccessTokenSilently();
            const result = await markNotificationAsRead(token, notification);

            if (!result.error) {
                if (notifications.some(n => n.entityExtId.toLowerCase() === notification.entityExtId.toLowerCase())) {
                    const updatedNotifications = notifications.map(n => {
                        if (n.entityExtId.toLowerCase() === notification.entityExtId.toLowerCase()) {
                            n.isRead = true;
                        }
    
                        return n;
                    });
                    
                    setNotifications(updatedNotifications);
                } else {
                    await resetNotifications(token);
                }
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    const markAsUnread = async (notification) => {
        try {
            var token = await getAccessTokenSilently();
            const result = await markNotificationAsUnread(token, notification);

            if (!result.error) {
                if (notifications.some(n => n.entityExtId.toLowerCase() === notification.entityExtId.toLowerCase())) {
                    const updatedNotifications = notifications.map(n => {
                        if (n.entityExtId.toLowerCase() === notification.entityExtId.toLowerCase()) {
                            n.isRead = false;
                        }
    
                        return n;
                    });
                    
                    setNotifications(updatedNotifications);
                } else {
                    await resetNotifications(token);
                }
            } else {
                console.error(result);
            }

            return result;
        } catch (error) {
            console.error(error);
        }
    }

    const resetNotifications = async (token) => {
        try {
            var notifications = await getUnreadNotifications(token);
            if (!notifications.error) {
                setNotifications(notifications);
            } else {
                console.error(notifications);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return <NotificationsContext.Provider
        value={{
            isNotificationsLoading,
            notifications,
            markPendingAsUnread,
            markAllAsRead,
            markAsRead,
            markAsUnread
        }}>
        {children}
    </NotificationsContext.Provider>
}