import { useAuth0 } from '@auth0/auth0-react';
import { getParkingAreas } from 'api/admin';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import ParkingSpotMap from 'components/common/parkingSpotMap';
import React, { useEffect, useState } from 'react';

const HomePage = () => {
    const [parkingAreas, setParkingAreas] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const load = async () => {
            try {
                var token = await getAccessTokenSilently();
                var result = await getParkingAreas(token);

                if (!result.error) {
                    setParkingAreas(result);
                } else {
                    console.error(result);
                }

            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        setIsLoading(true);
        load();
    }, [getAccessTokenSilently]);

    if (!isLoading) {
        return (
            <ParkingSpotMap parkingAreas={parkingAreas} />
        )
    } else {
        return (
            <LoadingSkeleton />
        );
    }
};

export default HomePage;