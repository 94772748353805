import React, { useState } from 'react';
import Lottie from 'lottie-react';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import { useAuth0 } from '@auth0/auth0-react';
import { joinOrganization, leaveOrganization } from 'api/admin';
import { GreenMobileIcon } from 'components/icons/icons';
import { ReactComponent as JoinIcon } from 'assets/icons/ico_organizations_add.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/ico_close.svg';
import { NextIcon } from 'components/icons/icons';
import Highlighter from 'react-highlight-words';

const OrganizationItem = ({organization, i, searchText}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState();

    const url = `${process.env.REACT_APP_WEB_PORTAL_BASE_URL}/${organization.organizationExtId}`;

    const handleJoinOrganization = async () => {
        try {
            setIsLoading(true);

            const token = await getAccessTokenSilently();
            const result = await joinOrganization(token, organization.organizationExtId);

            if (result && !result.error) {
                organization.memberOf = true;
            }

            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const handleLeaveOrganization = async () => {
        try {
            setIsLoading(true);

            const token = await getAccessTokenSilently();
            const result = await leaveOrganization(token, organization.organizationExtId);

            if (result && !result.error) {
                organization.memberOf = false;
            }

            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <tr key={i} className="h-16 border-b-[1px] border-airpark-gray-300">
                <td className="border-r-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                    {organization.isMobileOrganization &&
                        <div className="flex h-16 w-full justify-center items-center">
                            <GreenMobileIcon />
                        </div>
                    }
                </td>
                <td className="pl-5 whitespace-nowrap">
                    <Highlighter
                        searchWords={[searchText]}
                        textToHighlight={organization.name ?? ''}
                    />
                </td>
                <td className="pl-5 whitespace-nowrap">
                    <Highlighter
                        searchWords={[searchText]}
                        textToHighlight={organization.organizationNumber ?? ''}
                    />
                </td>
                <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                    {isLoading &&
                        <div className="flex h-16 w-full justify-center items-center">
                            <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                        </div>
                    }
                    {!isLoading &&
                        <>
                            {!organization.memberOf &&
                                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); handleJoinOrganization(); }} >
                                    <JoinIcon className="h-5 w-5 ml-1" /><span className="ml-1 mr-1">Join</span>
                                </button>
                            }
                            {organization.memberOf &&
                                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); handleLeaveOrganization(); }} >
                                    <LeaveIcon className="h-5 w-5 ml-1" /><span className="ml-1 mr-1">Leave</span>
                                </button>
                            }
                        </>
                    }
                </td>
                <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                    <a className="flex h-16 w-full justify-center items-center" href={url} target="_blank">
                        <NextIcon className="h-5 w-5 ml-1" />
                    </a>
                </td>
            </tr>
        </>
    );
};

export default OrganizationItem;