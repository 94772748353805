import React from 'react';
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg';


const Logo = () => {
    return (
        <AirParkLogo />
    );
};

export default Logo;