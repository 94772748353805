import React, { useEffect, useRef, useState } from "react";

const Menu = ({ onCloseClick, children, center }) => {
    const [className, setClassName] = useState('flex fixed flex-col gap-3 min-w-[224px] z-20 shadow-xl rounded-lg bg-white p-6 transition-all ease-in-out duration-100 scale-75 opacity-50');
    const containerRef = useRef();
    const [position, setPosition] = useState();


    useEffect(() => {
        setPositionOfElement(containerRef.current);
        setTimeout(() => {
            setClassName('flex fixed flex-col gap-3 min-w-[224px] z-20 shadow-xl rounded-lg bg-white p-6 transition-all duration-100 scale-100 opacity-100');
        }, 10);


        const handleScroll = event => {
            onCloseClick();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [onCloseClick]);

    const setPositionOfElement = (element) => {
        const rect = element.getBoundingClientRect();
        setPosition({ top: rect.top, left: rect.left - 220, center: rect.center });
    }

    return (
        <div ref={containerRef}>
            {position &&
                <>
                    <div className="fixed inset-0 z-10" onClick={(e) => { e.preventDefault(); onCloseClick(); }} />
                    <div style={{ top: position.top, left: center ? position.center : position.left }} className={className}>
                        {children}
                    </div>
                </>
            }
        </div>
    )
}

export default Menu;