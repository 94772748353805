import { DrawingManager, GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';
import googleMapStyle from 'styles/googleMapStyle';

const ParkingSpotMap = ({ parkingAreas }) => {
    const mapContainerStyle = {
        width: '100%',
        maxWidth: '100%',
        height: '600px'
    };

    const [center] = useState({ lat: 56, lng: 12 });
    const [libraries] = useState(['drawing']);
    const [map, setMap] = useState();

    useEffect(() => {
        if (map && parkingAreas) {

            let allPolygonCoordinates = [];

            parkingAreas.forEach((parkingArea) => {
                if (!parkingArea.polygonCoordinates) {
                    return;
                }

                let polygonCoordinates = [];

                parkingArea.polygonCoordinates.forEach((c) => {
                    const coordinates = { lat: c.latitude, lng: c.longitude };
                    polygonCoordinates.push(coordinates);
                    allPolygonCoordinates.push(coordinates);
                });

                var bounds = new window.google.maps.LatLngBounds();

                for (let i = 0; i < polygonCoordinates.length; i++) {
                    bounds.extend(polygonCoordinates[i]);
                }

                const infowindow = new window.google.maps.InfoWindow({
                    content: parkingArea.name + `<br/><br/><a href="/parking-areas/${parkingArea.parkingAreaExtId.toLowerCase()}">Open</a>`,
                    ariaLabel: "Uluru",
                  });

                const marker = new window.google.maps.Marker({
                    position: bounds.getCenter(),
                    map,
                    icon: "/assets/icons/map/pin.svg",
                    title: parkingArea.name
                });

                marker.addListener("click", () => {
                    infowindow.open({
                      anchor: marker,
                      map,
                    });
                  });
            });

            var bounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < allPolygonCoordinates.length; i++) {
                bounds.extend(allPolygonCoordinates[i]);
            }

            map.fitBounds(bounds);
        }
    }, [map, parkingAreas]);

    const onLoadGoogleMap = (ref) => {
        setMap(ref);
        ref.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        ref.setTilt(0);
    }

    return (
        <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
            <GoogleMap
                onLoad={onLoadGoogleMap}
                id='map'
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
                options={{
                    styles: googleMapStyle,
                    disableDefaultUI: false
                }}>
            </GoogleMap>
        </LoadScriptNext>
    );
};

export default ParkingSpotMap;