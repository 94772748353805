import React from 'react';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Logo from 'components/icons/logo';
import Lottie from 'lottie-react';

const Loading = () => {
    return (
        <div className="flex h-screen items-center justify-center">
            <div className="absolute top-12">
                <Logo />
            </div>
            <div className="flex flex-col items-center">
                <Lottie className="h-10 w-10" animationData={animSpinnerGreen} loop={true} />
                <div className="flex h-10 items-center mt-2">
                    <h1 className="text-2xl font-medium">Loading...</h1>
                </div>
            </div>
        </div>
    );
};

export default Loading;