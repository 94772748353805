import { useAuth0 } from '@auth0/auth0-react';
import { deleteControlFee, getControlFee, updateControlFeeStatus } from 'api/admin';
import { postSetControlFeeAsPaid } from 'api/payment';
import { DrawingManager, GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import LoadingSkeleton from 'components/common/loadingSkeleton';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import googleMapStyle from 'styles/googleMapStyle';
import ButtonText from 'components/common/buttonText';
import Modal from 'components/common/modal';
import BetweenPagesMessage from 'components/common/pageMessage';
import useNotifications from 'context/notifications';

const ControlFeePage = () => {
    const navigate = useNavigate();
    const [currentPolygon, setCurrentPolygon] = useState();
    const { controlFeeExtId } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const [controlFee, setControlFee] = useState();
    const [isLoading, setIsLoading] = useState();
    const mapContainerStyle = {
        width: '100%',
        maxWidth: '100%',
        height: '300px'
    };

    const [isUpdatingApproved, setIsUpdatingApproved] = useState();
    const [isUpdatingDeclined, setIsUpdatingDeclined] = useState();
    const [isDeleting, setIsDeleting] = useState();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState();
    const [showDeletedMessage, setShowDeletedMessage] = useState();

    const [isPaying, setIsPaying] = useState();

    const [center, setCenter] = useState({ lat: 56, lng: 12 });
    const [libraries] = useState(['drawing']);
    const [map, setMap] = useState();

    const [markedAsUnread, setMarkedAsUnread] = useState();
    const [isMarkingAsUnread, setIsMarkingAsUnread] = useState();

    const { markAsRead, markAsUnread } = useNotifications();

    console.log(controlFee)

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            try {
                var token = await getAccessTokenSilently();
                var result = await getControlFee(token, controlFeeExtId);
                await markAsRead({ entityExtId: controlFeeExtId, notificationTypeId: 1 });

                console.log(result);

                if (!result.error) {
                    setControlFee(result);
                } else {
                    console.error(result);
                }
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        load();

    }, [controlFeeExtId]);

    useEffect(() => {
        if (controlFee && controlFee.parkingArea && controlFee.parkingArea.polygonCoordinates && window.google && window.google.maps && map) {

            if (currentPolygon) {
                currentPolygon.setMap(null);
            }

            let polygonCoordinates = [];

            controlFee.parkingArea.polygonCoordinates.forEach((c) => {
                polygonCoordinates.push({ lat: c.latitude, lng: c.longitude })
            });

            const polygon = new window.google.maps.Polygon({
                paths: polygonCoordinates,
                fillColor: '#33D085',
                fillOpacity: '0.4',
                strokeWeight: 3,
                editable: false,
                zIndex: 1,
                strokeColor: '#33D085',
                draggable: false
            });

            polygon.setMap(map);

            var bounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < polygonCoordinates.length; i++) {
                bounds.extend(polygonCoordinates[i]);
            }

            bounds.extend({ lat: controlFee.userLatitude, lng: controlFee.userLongitude });

            map.fitBounds(bounds);

            setCurrentPolygon(polygon);
        }

    }, [controlFee, window.google, map]);

    const onLoadGoogleMap = (ref) => {
        setMap(ref);
        ref.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        ref.setTilt(0);
    }

    const handleControlFeeApprove = async () => {
        if (isUpdatingApproved) {
            return;
        }

        setIsUpdatingApproved(true);
        try {
            var token = await getAccessTokenSilently();
            var result = await updateControlFeeStatus(token, {
                status: 'Approved',
                controlFeeExtId: controlFee.controlFeeExtId
            });

            if (!result.error) {
                setControlFee(result);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        setIsUpdatingApproved(false);
    }


    const handleControlFeeDecline = async () => {
        if (isUpdatingDeclined) {
            return;
        }

        setIsUpdatingDeclined(true);
        try {
            var token = await getAccessTokenSilently();
            var result = await updateControlFeeStatus(token, {
                status: 'Declined',
                controlFeeExtId: controlFee.controlFeeExtId
            });

            if (!result.error) {
                setControlFee(result);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        setIsUpdatingDeclined(false);
    }

    const handleControlFeeDelete = async () => {
        if (isDeleting) {
            return;
        }

        setIsDeleting(true);
        try {
            var token = await getAccessTokenSilently();
            const result = await deleteControlFee(token, controlFee.controlFeeExtId);

            if (!result.error) {
                setShowDeleteConfirmation(false);
                setShowDeletedMessage(true);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        setIsDeleting(false);
    }

    const handlePayControlFee = async () => {
        if (isPaying) {
            return;
        }

        setIsPaying(true);
        try {
            var token = await getAccessTokenSilently();
            const result = await postSetControlFeeAsPaid(token, controlFee.controlFeeExtId);

            if (!result.error) {
                setControlFee({...controlFee, ...{isPaid: true}})
            } else {
                console.error(result);
            }

        } catch (error) {
            console.error(error);
        }
        setIsPaying(false);
    }

    
    const handleMarkAsUnread = async () => {
        if (isMarkingAsUnread) {
            return;
        }

        setIsMarkingAsUnread(true);
        const result = await markAsUnread({ entityExtId: controlFeeExtId, notificationTypeId: 1 });
        if (!result.error) {
            setMarkedAsUnread(true);
        }
        setIsMarkingAsUnread(false);
    }

    if (isLoading) {
        return (
            <LoadingSkeleton />
        );
    } else if (controlFee && !isLoading) {
        return (
            <>
                <div className="flex flex-col w-full">
                    <div className="flex flex-col shadow-lg rounded-xl p-6 w-full mb-6">
                        <div className="flex flex-col mb-6 gap-6">
                            <div className="flex w-full justify-between items-center">
                                <h1 className="flex font-medium text-2xl">Control fee</h1>
                                <div className="flex gap-3 justify-end">
                                    <ButtonText
                                        onClick={(e) => { e.preventDefault(); handleMarkAsUnread(); }}
                                        isSecondary={true}
                                        disabled={markedAsUnread}
                                        isLoading={isMarkingAsUnread}>
                                        Mark as unread
                                    </ButtonText>
                                    <ButtonText onClick={(e) => { e.preventDefault(); setShowDeleteConfirmation(true); }} isSecondary={true}>
                                        Delete
                                    </ButtonText>
                                    <ButtonText disabled={controlFee.controlFeeStatus == 'Declined'} onClick={(e) => { e.preventDefault(); handleControlFeeDecline(); }} isSecondary={false} isLoading={isUpdatingDeclined}>{controlFee.controlFeeStatus == 'Declined' ? 'Declined' : 'Decline'}</ButtonText>
                                    {controlFee.controlFeeStatus != 'Approved' ? 
                                        <ButtonText disabled={controlFee.controlFeeStatus == 'Approved'} onClick={(e) => { e.preventDefault(); handleControlFeeApprove(); }} isSecondary={false} isLoading={isUpdatingApproved}>{controlFee.controlFeeStatus == 'Approved' ? 'Approved' : 'Approve'}</ButtonText>
                                    :
                                        <ButtonText disabled onClick={(e) => { e.preventDefault(); handlePayControlFee(); }} isSecondary={false} isLoading={isPaying}>{controlFee.isPaid ? 'Paid' : 'Pay'}</ButtonText>
                                    }

                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="flex flex-col w-1/2">
                                    <span className="font-medium">License number</span>
                                    <span>{controlFee.licensePlateNumber}</span>
                                    <span className="font-medium mt-3">Opened</span>
                                    <span>{controlFee.isOpened ? 'Yes': 'No'}</span>
                                    <span className="font-medium mt-3">Fee</span>
                                    <span>{controlFee.friendlyPrice}</span>
                                    <span className="font-medium mt-3">Reported at</span>
                                    <span>{controlFee.createdDate}</span>
                                    <span className="font-medium mt-3">Notes</span>
                                    <span>{controlFee.notes != '' ? controlFee.notes : 'No notes available'}</span>
                                    <span className="font-medium mt-3">Reported by</span>
                                    <span>{controlFee.reportedByUser?.firstName} {controlFee.reportedByUser?.lastName}</span>
                                    <span><a href={`mailto:${controlFee.reportedByUser?.email}`}>{controlFee.reportedByUser?.email}</a></span>
                                    <span><a href={`tel:${controlFee.reportedByUser?.phone}`}>{controlFee.reportedByUser?.phone}</a></span>
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
                                        <GoogleMap
                                            onLoad={onLoadGoogleMap}
                                            id='map'
                                            mapContainerStyle={mapContainerStyle}
                                            center={center}
                                            zoom={10}
                                            options={{
                                                styles: googleMapStyle,
                                                disableDefaultUI: true
                                            }}>
                                            <DrawingManager
                                                onLoad={() => { }}
                                                drawingMode='none'
                                                options={{
                                                    drawingControl: false,
                                                    polygonOptions: {
                                                        fillColor: '#33D085',
                                                        fillOpacity: '0.4',
                                                        strokeWeight: 3,
                                                        editable: false,
                                                        zIndex: 1,
                                                        strokeColor: '#33D085',
                                                        draggable: false
                                                    }
                                                }}
                                                onPolygonComplete={() => { }}
                                            />
                                            {controlFee.userLatitude && controlFee.userLongitude && controlFee.userLatitude != 0 && controlFee.userLongitude != 0 &&
                                                <Marker
                                                    icon='/assets/icons/map/pin.svg'
                                                    position={{ lat: controlFee.userLatitude, lng: controlFee.userLongitude }} />
                                            }
                                        </GoogleMap>
                                    </LoadScriptNext>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col shadow-lg rounded-xl p-6 w-full">
                        <h1 className="font-medium text-2xl mb-6">Files</h1>
                        <div className="flex flex-col mb-6">
                            <span className="font-medium pb-1">Control fee images/videos</span>
                            <div className="flex gap-3">
                                {controlFee.controlFeeFiles?.map((file, i) => {
                                    return (
                                        <a className="flex w-fit h-80" key={i} href={file} target="_blank">
                                            <img className="shadow rounded-xl" src={file} />
                                        </a>
                                    )
                                })}
                                {controlFee.controlFeeFiles?.length < 1 &&
                                    <span>No control fee files</span>
                                }
                            </div>
                        </div>
                    </div >
                </div>
                <Modal
                    title='Delete?'
                    display={showDeleteConfirmation}
                    setDisplay={(display) => { setShowDeleteConfirmation(display); }}
                    primaryButtonTitle="Yes, delete"
                    primaryButtonOnClick={(e) => { e.preventDefault(); handleControlFeeDelete(); }}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonOnClick={(e) => { e.preventDefault(); setShowDeleteConfirmation(false); }}
                    isLoading={isDeleting}>
                    <span>Are you sure you want to delete the control fee? This cannot be undone!</span>
                </Modal>
                <BetweenPagesMessage
                    title="Deleted"
                    description="Control fee was deleted"
                    display={showDeletedMessage}
                    setDisplay={(display) => { setShowDeletedMessage(display) }}
                    onClick={(e) => { e.preventDefault(); navigate("/control-fees"); }} />
            </>);
    }
};

export default ControlFeePage;