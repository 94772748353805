import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getOrganizations } from 'api/admin';
import OrganizationTable from 'components/pageSpecific/organizations/organizationTable';
import Widget from 'components/dashboard/widget';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import InputSearch from 'components/common/inputSearch';
import Paging from 'components/common/paging';

const OrganizationsPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [organizations, setOrganizations] = useState();
    const [searchText, setSearchText] = useState();
    const [isLoading, setIsLoading] = useState();
    const [filteredOrganizations, setFilteredOrganizations] = useState();
    const [currentPageOrganizations, setCurrentPageOrganizations] = useState();
    const [organizationPages, setOrganizationPages] = useState();
    const [currentOrganizationPage, setCurrentOrganizationPage] = useState(0);

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            try {
                const token = await getAccessTokenSilently();
                const organizations = await getOrganizations(token);
                setOrganizations(organizations);
                
                setOrganizationPages(Math.ceil(organizations.length / 10));
                setCurrentOrganizationPage(0);
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        };

        load();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (searchText && organizations) {
            const filteredOrganizations = [...organizations
                .filter((organization) => {
                    if (organization.name.toLowerCase().includes(searchText.toLowerCase())) { 
                        return true; 
                    }

                    if (organization.organizationNumber?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }
                })
            ];
            setFilteredOrganizations(filteredOrganizations);
            setCurrentPageOrganizations(filteredOrganizations.slice(currentOrganizationPage * 10, ((currentOrganizationPage * 10) + 10)))
            setOrganizationPages(Math.ceil(filteredOrganizations.length / 10))
        } else if (organizations) {
            setFilteredOrganizations([...organizations]);
            setCurrentPageOrganizations(organizations.slice(currentOrganizationPage * 10, ((currentOrganizationPage * 10) + 10)))
            setOrganizationPages(Math.ceil(organizations.length / 10))
        }
    }, [searchText, organizations, currentOrganizationPage]);

    useEffect(() => {
        setCurrentOrganizationPage(0);
    }, [searchText])

    return (
        <>
            <Widget>
                <div className="flex gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className="text-xl font-medium mb-1">Manage organizations</h1>
                        <span>Add yourself to any of the available organizations.</span>
                    </div>
                </div>
            </Widget>
            {!isLoading &&
                <>
                    <Widget className="mt-6">
                        <div className="flex items-center justify-between mb-6">
                            <h1 className="text-xl font-medium">Organizations</h1>
                            <div className="flex items-center gap-3">
                                <InputSearch
                                    placeHolder={'Search...'}
                                    value={searchText ?? ''}
                                    onChange={(value) => { setSearchText(value); }} />
                            </div>
                        </div>
                        <OrganizationTable organizations={currentPageOrganizations} searchText={searchText} />
                        <div className="p-6">
                            <Paging
                                totalNumberOfPages={organizationPages}
                                currentPage={currentOrganizationPage}
                                setCurrentPage={setCurrentOrganizationPage}
                            />
                        </div>
                    </Widget>
                </>
            }
            {isLoading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
        </>
    )
};

export default OrganizationsPage;