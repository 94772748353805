import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getCurrentUserTestToken, updateUserTestToken } from 'api/admin';
import jwtDecode from 'jwt-decode';
import ButtonText from 'components/common/buttonText';

const SettingsPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [userTestAccessToken, setUserTestAccessToken] = useState();
    const [decodedToken, setDecodedToken] = useState();
    const [isUpdatingUserTestToken, setIsUpdatingUserTestToken] = useState(false);

    useEffect(() => {

        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const userTestTokenResult = await getCurrentUserTestToken(token);
                setUserTestAccessToken(userTestTokenResult);
                const decodedToken = jwtDecode(userTestTokenResult);
                setDecodedToken(decodedToken);
            } catch (error) {
                console.error(error);
            }
        };

        load();
    }, []);

    const handleUpdateUserTestToken = async () => {
        setIsUpdatingUserTestToken(true);
        try {
            const token = await getAccessTokenSilently();
            const userTestTokenResult = await updateUserTestToken(token, { token: token });
            setUserTestAccessToken(userTestTokenResult);
            const decodedToken = jwtDecode(userTestTokenResult);
            setDecodedToken(decodedToken);
        } catch (error) {
            console.error(error);
        } finally {
            setIsUpdatingUserTestToken(false);
        }
    }

    return (
        <div className="flex flex-col gap-y-6">
            <div className="flex flex-col w-full rounded-xl shadow-lg p-6">
                <div className="flex flex-col">
                    <h2 className="text-xl font-medium">Settings</h2>
                    <p className="text-gray-500 mt-1">Settings for AirPark</p>
                </div>
            </div>
            {userTestAccessToken && decodedToken &&
                <div className="flex w-full rounded-xl shadow-lg p-6">
                    <div className="flex flex-col w-1/2">
                        <h2 className="text-xl font-medium">User test access token</h2>
                        <p className="text-gray-500 mt-1 mb-3">Used by Apple and Google to test the App</p>
                        <p>Created {new Date(decodedToken.iat * 1000).toLocaleDateString()}</p>
                        <p>Expires {new Date(decodedToken.exp * 1000).toLocaleDateString()}</p>
                        <ButtonText 
                            className="max-w-xs w-full mt-3"
                            isLoading={isUpdatingUserTestToken}
                            disabled={isUpdatingUserTestToken}
                            onClick={() => { handleUpdateUserTestToken() }}>
                                Update
                            </ButtonText>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <p className="flex break-all">{userTestAccessToken}</p>
                    </div>
                </div>
            }
        </div>
    );
};

export default SettingsPage;