import React from "react";
import Lottie from 'lottie-react';
import animSpinnerWhite from 'assets/animations/anim_spinner_white_20x20.json';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';

const ButtonText = ({ children, onClick, isSecondary, className, isLoading, disabled }) => {

    let textColor = isSecondary ? 'text-airpark-green' : 'text-white';
    let shadow = isSecondary ? 'shadow-secondary-button' : 'shadow';
    let backgroundColor;

    if (!isSecondary) {
        if (!isLoading) {
            backgroundColor = 'bg-airpark-green';
        } else {
            backgroundColor = 'bg-airpark-green-background-opacity-70'
        }
    } else {
        backgroundColor = 'bg-white';
    }

    let baseClassName = `flex center text-base font-medium items-center justify-center pl-6 pr-6 h-11 rounded-3xl ${textColor} ${backgroundColor} ${shadow}`;

    if (className) {
        className = `${baseClassName} ${className}`;
    } else {
        className = baseClassName;
    }

    if (disabled) {
        className = `${className} opacity-50`;
    }

    return (
        <button disabled={disabled} onClick={(e) => { onClick(e); }} className={className}>
            {!isLoading &&
                <>
                    {children}
                </>
            }
            {isLoading &&
                <Lottie className="h-8 w-8" animationData={isSecondary ? animSpinnerGreen : animSpinnerWhite} loop={true} />
            }
        </button>
    )
}

export default ButtonText;