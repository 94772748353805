import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, useSearchParams } from 'react-router-dom';
import Toast from "components/messages/toast";

import LeftMenu from 'components/common/leftMenu';
import Loading from 'components/common/loading';
import TopBar from 'components/common/topBar';
import Unauthorized from 'components/common/unauthorized';
import { NotificationsProvider } from 'context/notifications';
import { useMessages } from 'context/messages';

const DefaultLayout = () => {

    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const [searchParams] = useSearchParams();
    const { toast } = useMessages();

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            if (!searchParams.get('error')) {
                loginWithRedirect();
            }
        }
    }, [isAuthenticated, isLoading, loginWithRedirect, searchParams]);

    if (!isLoading && isAuthenticated) {
        return (
                <NotificationsProvider>
                    <div className="flex flex-col min-h-screen overflow-x-hidden relative gap-y-6">
                        <TopBar />
                        <div className="flex grow pb-6">
                            <LeftMenu />
                            <div className="flex flex-col w-full overflow-x-hidden pl-6 pr-6 pb-1">
                                <Outlet />
                            </div>
                        </div>
                        <Toast />
                    </div>
                </NotificationsProvider>
        );
    } else if (isLoading && !isAuthenticated) {
        return (<Loading />)
    } else if (!isLoading && !isAuthenticated) {
        return (<Unauthorized title={searchParams.get('error')} description={searchParams.get('error_description')} />);
    }
};

export default DefaultLayout;