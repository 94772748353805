import { Route, Routes } from 'react-router-dom';
import DefaultLayout from 'layouts/defaultLayout';
import ScrollToTop from 'components/common/scrollToTop';

import HomePage from 'pages/home';
import ParkingAreasPage from 'pages/parkingAreas';
import ControlFeesPage from 'pages/controlFees/controlFees';
import ParkingAreaPage from 'pages/parkingArea';
import ControlFeePage from 'pages/controlFee';
import BookingsPage from 'pages/bookings';
import SettingsPage from 'pages/settings';
import OrganizationsPage from 'pages/organizations';
import AdminsPage from 'pages/admins';
import AirPassesPage from 'pages/airpasses';

import './App.css';

function App() {
  return (
      <ScrollToTop>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/parking-areas" element={<ParkingAreasPage />} />
            <Route path="/parking-areas/:parkingAreaExtId" element={<ParkingAreaPage />} />
            <Route path="/control-fees" element={<ControlFeesPage />} />
            <Route path="/control-fees/:controlFeeExtId" element={<ControlFeePage />} />
            <Route path="/bookings" element={<BookingsPage />} />
            <Route path="/organizations" element={<OrganizationsPage />} />
            <Route path="/admins" element={<AdminsPage />} />
            <Route path="/airpasses" element={<AirPassesPage />} />
            <Route path="/settings" element={<SettingsPage />} />
          </Route>
        </Routes>
      </ScrollToTop>);
}

export default App;
