const BASE_URL = process.env.REACT_APP_API_PAYMENTS_BASE_URL

export const postSetControlFeeAsPaid = async (token, controlFeeExtId) => {
    const response = await fetch(BASE_URL + `/Invoice/pay-controlfee/${controlFeeExtId}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response
  }