import { useAuth0 } from '@auth0/auth0-react';
import { deleteParkingArea, getParkingArea, markNotificationAsRead, markNotificationAsUnread, updateParkingAreaStatus } from 'api/admin';
import { DrawingManager, GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import LoadingSkeleton from 'components/common/loadingSkeleton';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import googleMapStyle from 'styles/googleMapStyle';
import ButtonText from 'components/common/buttonText';
import Modal from 'components/common/modal';
import BetweenPagesMessage from 'components/common/pageMessage';
import useNotifications from 'context/notifications';

const ParkingAreaPage = () => {
    const [currentPolygon, setCurrentPolygon] = useState();
    const { parkingAreaExtId } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const [parkingArea, setParkingArea] = useState();
    const [isLoading, setIsLoading] = useState();
    const mapContainerStyle = {
        width: '100%',
        maxWidth: '100%',
        height: '300px'
    };

    const [isUpdatingApproved, setIsUpdatingApproved] = useState();
    const [isUpdatingDeclined, setIsUpdatingDeclined] = useState();
    const [isDeleting, setIsDeleting] = useState();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState();
    const [showParkingAreaDeletedMessage, setShowParkingAreaDeletedMessage] = useState();

    const [center] = useState({ lat: 56, lng: 12 });
    const [libraries] = useState(['drawing']);
    const [map, setMap] = useState();
    const navigate = useNavigate();

    const [markedAsUnread, setMarkedAsUnread] = useState();
    const [isMarkingAsUnread, setIsMarkingAsUnread] = useState();
    
    const { markAsRead, markAsUnread } = useNotifications();

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            try {
                var token = await getAccessTokenSilently();
                var result = await getParkingArea(token, parkingAreaExtId);
                await markAsRead({ entityExtId: parkingAreaExtId, notificationTypeId: 2 });

                if (!result.error) {
                    setParkingArea(result);
                } else {
                    console.error(result);
                }
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        load();

    }, [parkingAreaExtId]);

    useEffect(() => {
        if (parkingArea && parkingArea.polygonCoordinates && window.google && window.google.maps && map) {

            if (currentPolygon) {
                currentPolygon.setMap(null);
            }

            let polygonCoordinates = [];

            parkingArea.polygonCoordinates.forEach((c) => {
                polygonCoordinates.push({ lat: c.latitude, lng: c.longitude })
            });

            const polygon = new window.google.maps.Polygon({
                paths: polygonCoordinates,
                fillColor: '#33D085',
                fillOpacity: '0.4',
                strokeWeight: 3,
                editable: false,
                zIndex: 1,
                strokeColor: '#33D085',
                draggable: false
            });

            polygon.setMap(map);

            var bounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < polygonCoordinates.length; i++) {
                bounds.extend(polygonCoordinates[i]);
            }

            map.fitBounds(bounds);

            setCurrentPolygon(polygon);
        }

    }, [parkingArea, window.google, map]);

    const onLoadGoogleMap = (ref) => {
        setMap(ref);
        ref.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        ref.setTilt(0);
    }

    const handleParkingAreaApprove = async () => {
        if (isUpdatingApproved) {
            return;
        }

        setIsUpdatingApproved(true);
        try {
            var token = await getAccessTokenSilently();
            var result = await updateParkingAreaStatus(token, {
                status: 'Approved',
                parkingAreaExtId: parkingArea.parkingAreaExtId
            });

            if (!result.error) {
                setParkingArea(result);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        setIsUpdatingApproved(false);
    }

    const handleParkingAreaDecline = async () => {
        if (isUpdatingDeclined) {
            return;
        }

        setIsUpdatingDeclined(true);
        try {
            var token = await getAccessTokenSilently();
            var result = await updateParkingAreaStatus(token, {
                status: 'Declined',
                parkingAreaExtId: parkingArea.parkingAreaExtId
            });

            if (!result.error) {
                setParkingArea(result);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        setIsUpdatingDeclined(false);
    }

    const handleParkingAreaDelete = async () => {
        if (isDeleting) {
            return;
        }

        setIsDeleting(true);
        try {
            var token = await getAccessTokenSilently();
            const result = await deleteParkingArea(token, parkingArea.parkingAreaExtId);

            if (!result.error) {
                setShowDeleteConfirmation(false);
                setShowParkingAreaDeletedMessage(true);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }
        setIsDeleting(false);
    }

    const handleMarkAsUnread = async () => {
        if (isMarkingAsUnread) {
            return;
        }

        setIsMarkingAsUnread(true);
        const result = await markAsUnread({ entityExtId: parkingAreaExtId, notificationTypeId: 2 });
        if (!result.error) {
            setMarkedAsUnread(true);
        }
        setIsMarkingAsUnread(false);
    }

    if (isLoading) {
        return (
            <LoadingSkeleton />
        );
    } else if (parkingArea && !isLoading) {
        return (
            <>
                <div className="flex flex-col w-full">
                    <div className="flex flex-col shadow-lg rounded-xl p-6 w-full mb-6">
                        <div className="flex flex-col mb-6 gap-6">
                            <div className="flex w-full justify-between items-center">
                                <h1 className="flex font-medium text-2xl">
                                    {parkingArea.name}
                                </h1>
                                <div className="flex gap-3 justify-end">
                                    <ButtonText
                                        onClick={(e) => { e.preventDefault(); handleMarkAsUnread(); }}
                                        isSecondary={true}
                                        disabled={markedAsUnread}
                                        isLoading={isMarkingAsUnread}>
                                        Mark as unread
                                    </ButtonText>
                                    <ButtonText
                                        onClick={(e) => { e.preventDefault(); setShowDeleteConfirmation(true); }}
                                        isSecondary={true}>
                                        Delete
                                    </ButtonText>
                                    <ButtonText
                                        disabled={parkingArea.parkingAreaStatus == 'Declined'}
                                        onClick={(e) => { e.preventDefault(); handleParkingAreaDecline(); }}
                                        isSecondary={false} isLoading={isUpdatingDeclined}>
                                        {parkingArea.parkingAreaStatus == 'Declined' ? 'Declined' : 'Decline'}
                                    </ButtonText>
                                    <ButtonText
                                        disabled={parkingArea.parkingAreaStatus == 'Approved'}
                                        onClick={(e) => { e.preventDefault(); handleParkingAreaApprove(); }}
                                        isSecondary={false}
                                        isLoading={isUpdatingApproved}>
                                        {parkingArea.parkingAreaStatus == 'Approved' ? 'Approved' : 'Approve'}
                                    </ButtonText>
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="flex flex-col w-1/2">
                                    <span className="font-medium">Description</span>
                                    <span>{parkingArea.description}</span>
                                    <span className="font-medium mt-3">Created by</span>
                                    <span>{parkingArea.user.firstName} {parkingArea.user.lastName}</span>
                                    <span><a href={`mailto:${parkingArea.user.email}`}>{parkingArea.user.email}</a></span>
                                    <span><a href={`tel:${parkingArea.user.phone}`}>{parkingArea.user.phone}</a></span>
                                    <span className="font-medium mt-3">Organization</span>
                                    <span>{parkingArea.organizationName}</span>
                                    <span className="font-medium mt-3">Address</span>
                                    <span className="">{parkingArea.address}, {parkingArea.zipCode} {parkingArea.city}</span>
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <LoadScriptNext libraries={libraries} googleMapsApiKey="AIzaSyD6UTBXOfbCvJWhKlK7gKGCBYzQ9ECNu2c">
                                        <GoogleMap
                                            onLoad={onLoadGoogleMap}
                                            id='map'
                                            mapContainerStyle={mapContainerStyle}
                                            center={center}
                                            zoom={10}
                                            options={{
                                                styles: googleMapStyle,
                                                disableDefaultUI: true
                                            }}>
                                            <DrawingManager
                                                onLoad={() => { }}
                                                drawingMode='none'
                                                options={{
                                                    drawingControl: false,
                                                    polygonOptions: {
                                                        fillColor: '#33D085',
                                                        fillOpacity: '0.4',
                                                        strokeWeight: 3,
                                                        editable: false,
                                                        zIndex: 1,
                                                        strokeColor: '#33D085',
                                                        draggable: false
                                                    }
                                                }}
                                                onPolygonComplete={() => { }}
                                            />
                                        </GoogleMap>
                                    </LoadScriptNext>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col shadow-lg rounded-xl p-6 w-full">
                        <h1 className="font-medium text-2xl mb-6">Files</h1>
                        <div className="flex flex-col mb-6">
                            <span className="font-medium pb-1">Proof of ownership</span>
                            {parkingArea.parkingAreaImageFiles && parkingArea.proofOfOwnershipFiles.map((file, i) => {
                                return (
                                    <a key={i} href={file} className="text-airpark-green" target="_blank">Proof of ownership file</a>
                                )
                            })}
                        </div>

                        <span className="font-medium pb-1">Parking area images</span>
                        <div className="flex flex-wrap mb-6 gap-3">
                            {parkingArea.parkingAreaImageFiles && parkingArea.parkingAreaImageFiles.map((image, i) => {
                                return (
                                    <div className="flex h-24">
                                        <a className="flex relative" key={i} href={image} target="_blank">
                                            <img className="shadow rounded-xl" src={image} />
                                        </a>
                                    </div>
                                )
                            })}
                            {parkingArea.parkingAreaImageFiles && parkingArea.parkingAreaImageFiles.length < 1 &&
                                <span>No parking area image files</span>
                            }
                        </div>
                    </div >
                </div>
                <Modal
                    title='Delete?'
                    display={showDeleteConfirmation}
                    setDisplay={(display) => { setShowDeleteConfirmation(display); }}
                    primaryButtonTitle="Yes, delete"
                    primaryButtonOnClick={(e) => { e.preventDefault(); handleParkingAreaDelete(); }}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonOnClick={(e) => { e.preventDefault(); setShowDeleteConfirmation(false); }}
                    isLoading={isDeleting}>
                    <span>Are you sure you want to delete the parking area? This cannot be undone!</span>
                </Modal>
                <BetweenPagesMessage
                    title="Deleted"
                    description="Parking area was deleted"
                    display={showParkingAreaDeletedMessage}
                    setDisplay={(display) => { setShowParkingAreaDeletedMessage(display) }}
                    onClick={(e) => { e.preventDefault(); navigate("/parking-areas"); }} />
            </>);
    }
};

export default ParkingAreaPage;