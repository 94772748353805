import ButtonText from 'components/common/buttonText';
import { CloseIcon } from 'components/icons/icons';
import React from 'react';

const Modal = ({ title, children, primaryButtonTitle, primaryButtonOnClick, secondaryButtonTitle, secondaryButtonOnClick, display, setDisplay, isLoading }) => {

    const handleOverlayOnClick = () => {
        setDisplay(false);
    }

    if (display) {
        return (
            <>
                {/* Overlay */}
                <div className="fixed inset-0 z-10 bg-black bg-opacity-60">
                </div>
                {/* Modal */}
                <div onClick={(e) => handleOverlayOnClick(e)} className="flex fixed inset-0 z-20 overflow-y-auto overflow-x-hidden justify-center items-center">
                    <div onClick={(e) => e.stopPropagation()} className="flex pt-6 mb-6">
                        <div className="w-[375px] bg-white rounded-lg p-6">
                            <div className="flex justify-end">
                                <button className="w-[16px] h-[16px]" onClick={() => { setDisplay(false) }}>
                                    <CloseIcon />
                                </button>
                            </div>
                            <h2 className='text-xl font-medium pb-2'>{title}</h2>
                            {children}
                            {primaryButtonTitle && primaryButtonOnClick && secondaryButtonTitle && secondaryButtonOnClick &&
                                <div className="flex justify-between gap-3 mt-6">
                                     <ButtonText onClick={(e) => { secondaryButtonOnClick(e); }} isSecondary={true} className="w-full">
                                        {secondaryButtonTitle}
                                    </ButtonText>
                                    <ButtonText onClick={(e) => { primaryButtonOnClick(e); }}  isLoading={isLoading} className="w-full">
                                        {primaryButtonTitle}
                                    </ButtonText>
                                </div>
                            }
                            {primaryButtonTitle && primaryButtonOnClick && !secondaryButtonTitle && !secondaryButtonOnClick &&
                                <div className="flex justify-end mt-6">
                                    <ButtonText onClick={(e) => { primaryButtonOnClick(e); }} isLoading={isLoading}>
                                        {primaryButtonTitle}
                                    </ButtonText>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>);
    } else {
        return (null);
    }
};

export default Modal;