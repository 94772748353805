import React from 'react';
import OrganizationItem from './organizationItem';

const OrganizationTable = ({ organizations, searchText }) => {
    if (!organizations) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead className=''>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th></th>
                            <th className="pl-6 whitespace-nowrap">Organization Name</th>
                            <th className="pl-6 whitespace-nowrap">Organization Number</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {organizations && organizations.length > 0 && organizations.map((organization, i) => {
                            return (<OrganizationItem 
                                        key={i}
                                        organization={organization}
                                        i={i}
                                        searchText={searchText} />)
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default OrganizationTable;