import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getParkingAreas } from 'api/admin';
import DropDownMultiSelector from 'components/common/dropDownMultiSelector';
import { NextIcon, NotificationIcon, ThreeDotsIcon, GreenFilterIcon } from 'components/icons/icons';
import Paging from 'components/common/paging';
import { useNavigate } from 'react-router-dom';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import Widget from 'components/dashboard/widget';
import InputSearch from 'components/common/inputSearch';
import Highlighter from 'react-highlight-words';
import Menu from 'components/dashboard/menu/menu';
import CheckBox from 'components/common/checkbox/checkbox';

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import useNotifications from 'context/notifications';

const ParkingAreasPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState();
    const [parkingAreas, setParkingAreas] = useState();
    const [filteredParkingAreas, setFilteredParkingAreas] = useState();
    const [parkingAreaPages, setParkingAreaPages] = useState();
    const [currentParkingAreasPage, setCurrentParkingAreasPage] = useState(0);
    const [searchText, setSearchText] = useState();
    const [filteredStatuses, setFilteredStatuses] = useState([
        {value: "Pending", title: "Pending", isChecked: false},
        {value: "Declined", title: "Declined", isChecked: false},
        {value: "Approved", title: "Approved", isChecked: false}
    ]);
    const [showFilter, setShowFilter] = useState();
    const [showOptions, setShowOptions] = useState();
    const [isOptionsLoading, setIsOptionsLoading] = useState();
    const [activeFiltersCount, setActiveFiltersCount] = useState();
    const { notifications, markPendingAsUnread, markAllAsRead } = useNotifications();
    const [onlyShowUnread, setOnlyShowUnread] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                var token = await getAccessTokenSilently();
                var result = await getParkingAreas(token);

                if (!result.error) {
                    setParkingAreas(result);
                    setFilteredParkingAreas(result);
                    setParkingAreaPages(Math.ceil(result.length / 10));
                    setCurrentParkingAreasPage(0);
                } else {
                    console.error(result);
                }

            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        setIsLoading(true);
        load();
    }, [getAccessTokenSilently]);

    const handleStatusSelected = (status) => {
        const newOptions = filteredStatuses.map((option) => {
            if (option.value === status) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setFilteredStatuses(newOptions);
    }

    useEffect(() => {
        if (parkingAreas) {
            var filteredParkingAreas = parkingAreas;
            var numberOfCheckedOptions = 0;

            if (searchText) {
                filteredParkingAreas = filteredParkingAreas.filter((parkingArea) => {
                    return parkingArea.name.toLowerCase().includes(searchText.toLowerCase())
                        || parkingArea.organizationName.toLowerCase().includes(searchText.toLowerCase());
                });
            }
            
            if (filteredStatuses && filteredStatuses.some((option) => option.isChecked)) {
                filteredParkingAreas = filteredParkingAreas.filter((parkingArea) => {
                    return filteredStatuses.some((option) => {
                        return option.value === parkingArea.parkingAreaStatus && option.isChecked;
                    });
                });
                numberOfCheckedOptions += filteredStatuses.filter(x => x.isChecked).length;
            }

            if (notifications && onlyShowUnread) {
                filteredParkingAreas = filteredParkingAreas.filter((parkingArea) => {
                    return notifications.some(n => n.entityExtId.toLowerCase() === parkingArea.parkingAreaExtId.toLowerCase() && !n.isRead);
                })
                numberOfCheckedOptions += 1;
            }

            setFilteredParkingAreas(filteredParkingAreas);
            setParkingAreaPages(Math.ceil(filteredParkingAreas.length / 10));
            setCurrentParkingAreasPage(0);
            setActiveFiltersCount(numberOfCheckedOptions);
        }
    }, [searchText, filteredStatuses, parkingAreas, onlyShowUnread, notifications]);

    const handleMarkPendingAsUnread = async () => {
        if (isOptionsLoading) {
            return;
        }

        setShowOptions(false);
        setIsOptionsLoading(true);
        await markPendingAsUnread(2);
        setIsOptionsLoading(false);
    }

    const handleMarkAllAsRead = async () => {
        if (isOptionsLoading) {
            return;
        }

        setShowOptions(false);
        setIsOptionsLoading(true);
        await markAllAsRead(2);
        setIsOptionsLoading(false);
    }

    const clearAllFilters = () => {
        setSearchText('');
        setFilteredStatuses(filteredStatuses.map((option) => {
            option.isChecked = false;
            return option;
        }));
        setOnlyShowUnread(false);
    }

    if (parkingAreas && !isLoading) {
        return (
            <Widget>
                <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-6 gap-y-3">
                    <div className="flex gap-x-12 md:w-full">
                        <h1 className="text-xl font-medium">Parking Areas</h1>
                    </div>
                    <div className="fill-white mr-3">
                        <InputSearch
                            placeHolder={'Search...'}
                            value={searchText ?? ''}
                            onChange={(value) => { setSearchText(value); }} />
                    </div>
                    <div className="fill-white">
                        <button className="h-6 w-6" onClick={(e) => { e.preventDefault(); setShowFilter(!showFilter); }}>
                            <GreenFilterIcon className="block" />
                        </button>
                        {showFilter &&
                            <Menu onCloseClick={() => { setShowFilter(false); }}>
                                <button onClick={(e) => { e.preventDefault(); clearAllFilters(); }}>Clear all filters</button>
                                <div className="flex flex-col md:w-full">
                                    <DropDownMultiSelector
                                        onOptionSelected={(option) => {
                                            handleStatusSelected(option.value);
                                        }}
                                        options={filteredStatuses}
                                        defaultText={"Status"} />
                                </div>
                                <button className="flex" onClick={(e) => { e.preventDefault(); setOnlyShowUnread(!onlyShowUnread); }}>
                                    <div className="flex pointer-events-none">
                                        <CheckBox isChecked={onlyShowUnread} />
                                        <span className="flex ml-2">Only unread</span>
                                    </div>
                                </button>
                            </Menu>
                        }
                    </div>
                    <div className="fill-white mr-3">
                        {activeFiltersCount > 0 &&
                            <span>({activeFiltersCount})</span>
                        }
                    </div>
                    <div className="fill-white">
                        {isOptionsLoading &&
                            <div className="">
                                <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                            </div>
                        }
                        {!isOptionsLoading &&
                            <button className="h-6 w-6" onClick={(e) => { e.preventDefault(); setShowOptions(!showOptions); }}>
                                <ThreeDotsIcon className="block" />
                            </button>
                        }
                        {showOptions &&
                            <Menu onCloseClick={() => { setShowOptions(false); }}>
                                <button className="flex items-center" onClick={(e) => { e.preventDefault(); handleMarkPendingAsUnread(); }}>
                                    Mark pending as unread
                                </button>
                                <button className="flex items-center" onClick={(e) => { e.preventDefault(); handleMarkAllAsRead(); }}>
                                    Mark all as read
                                </button>
                            </Menu>
                        }
                    </div>
                </div>
                <div className="flex flex-col w-full rounded-xl shadow-lg">
                    <table className="table-auto mt-3 w-full">
                        <thead>
                            <tr className="text-left bg-airpark-gray-300 h-11">
                                <th className="pl-6"></th>
                                <th className="pl-6">Id</th>
                                <th className="pl-6">Name</th>
                                <th className="pl-6">Organization</th>
                                <th className="pl-6">Created</th>
                                <th className="pl-6">Status</th>
                                <th className="pl-6 pr-6"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredParkingAreas && filteredParkingAreas.slice(currentParkingAreasPage * 10, ((currentParkingAreasPage * 10) + 10)).map((item, i) => {
                                return (<ParkingAreaItem key={i} parkingArea={item} searchText={searchText} notifications={notifications} />)
                            })}
                        </tbody>
                    </table>
                    <div className="p-6">
                        <Paging totalNumberOfPages={parkingAreaPages} currentPage={currentParkingAreasPage} setCurrentPage={setCurrentParkingAreasPage} />
                    </div>
                </div>
            </Widget>            
        );
    } else {
        return (
           <LoadingSkeleton />
        )
    }
};

const ParkingAreaItem = ({ parkingArea, searchText, notifications }) => {

    const navigate = useNavigate();

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6">
                {notifications.some(n => n.entityExtId === parkingArea.parkingAreaExtId && !n.isRead) ? <NotificationIcon /> : <></>}
            </td>
            <td className="pl-6">{parkingArea.parkingAreaExtId}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={parkingArea.name}
                />
            </td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={parkingArea.organizationName}
                />
            </td>
            <td className="pl-6">{parkingArea.createdDateTime.substring(0, 10)}</td>
            <td className="pl-6 pr-6">{parkingArea.parkingAreaStatus}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); navigate(parkingArea.parkingAreaExtId.toLowerCase()) }}>
                    <NextIcon />
                </button>
            </td>
        </tr>
    )
}

export default ParkingAreasPage;