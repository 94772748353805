import { useAuth0 } from '@auth0/auth0-react';
import { CloseIcon } from 'components/icons/icons';
import React, { useEffect, useState } from 'react';
import InputSearch from 'components/common/inputSearch';
import { createAirPass, searchForOrganizations } from 'api/admin';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import Menu from 'components/dashboard/menu/menu';
import InputText from 'components/common/inputText';
import DropdownSelector from 'components/common/dropdownSelector';
import InputNumber from 'components/common/inputNumber';
import { useMessages } from 'context/messages';
import ButtonText from 'components/common/buttonText';

const NewAirPassModal = ({ setDisplay, display, airPassCreated }) => {
    
    const { getAccessTokenSilently } = useAuth0();
    const [searchText, setSearchText] = useState('');
    const [orgs, setOrgs] = useState();
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedParkingArea, setSelectedParkingArea] = useState();
    const [description, setDescription] = useState('');
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [canCreate, setCanCreate] = useState(false);

    const [isLoading, setIsLoading] = useState();
    const [showOptions, setShowOptions] = useState();
    const { showToast } = useMessages();

    const closeModal = () => {
        resetOrg();
        setDescription('');
        setLatitude(undefined);
        setLongitude(undefined);
        setDisplay(false);
    }

    const resetOrg = () => {
        setSelectedParkingArea(undefined);
        selectOrg(undefined);
        setDescription('');
        setLatitude(undefined);
        setLongitude(undefined);
    }

    const selectOrg = (org) => {
        setOrgs([]);
        setSearchText('');
        setSelectedOrg(org);

        if (org && org.parkingAreas && org.parkingAreas.length > 0) {
            setSelectedParkingArea(org.parkingAreas[0].parkingAreaExtId);
        }
    }

    useEffect(() => {
        if (searchText && searchText !== '')
        {
            setShowOptions(true);
            const delayDebounceFn = setTimeout(() => {
                const search = async () => {
                    try {
                        var token = await getAccessTokenSilently();
                        var orgs = await searchForOrganizations(token, searchText);
    
                        if (!orgs.error) {
                            setOrgs(orgs);
                        } else {
                            console.error(orgs);
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
    
                    setIsLoading(false);
                }
    
                setIsLoading(true);
                search();
            }, 1200);
            
            return () => clearTimeout(delayDebounceFn);
        }
    }, [getAccessTokenSilently, searchText]);

    useEffect(() => {
        let valid = true;
        if (!selectedParkingArea) {
            valid = false;
            console.error('Missing parking area');
        }

        if (!description || description === '') {
            valid = false;
            console.error('Missing description');
        }

        if (!latitude) {
            valid = false;
            console.error('Missing latitude');
        }

        if (!longitude) {
            valid = false;
            console.error('Missing longitude');
        }

        setCanCreate(valid);
    }, [selectedParkingArea, description, latitude, longitude]);

    const handleAirPassCreated = async () => {
        setSearchText('');
        setDisplay(false);
        await airPassCreated();
    }

    const handleCreateAirPass = async () => {
        setIsLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const success = await createAirPass(
                token,
                {
                    description: description,
                    parkingAreaExtId: selectedParkingArea,
                    latitude: latitude,
                    longitude: longitude
                });

            if (success) {
                handleAirPassCreated();
            } else {
                console.error(success);
                showToast('Failed to create!', `Was not successful in creating airpass!`, 'error');
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    if (display) {
        return (
            <>
                {/* Overlay */}
                <div className="fixed inset-0 z-10 bg-black bg-opacity-60">
                </div>
                {/* Modal */}
                <div onClick={(e) => closeModal(e)} className="flex fixed inset-0 z-20 justify-center items-center overflow-y-hidden overflow-x-hidden">
                    <div onClick={(e) => e.stopPropagation()} className="flex max-w-3xl w-full p-3 justify-center items-center">
                        <div className="flex flex-col w-full bg-white max-w-3xl rounded-lg p-6">
                            <div className="flex justify-end pb-6">
                                <button className="h-4" onClick={() => { closeModal() }}>
                                    <CloseIcon className="h-5 w-5" />
                                </button>
                            </div>
                            {!selectedOrg ?
                                <>
                                    <InputSearch
                                        className={'mb-0'}
                                        title={'Organization'}
                                        placeHolder={'Search...'}
                                        value={searchText ?? ''}
                                        onChange={(value) => { setSearchText(value); }} />
                                    <>
                                        {searchText !== '' && showOptions &&
                                            <Menu
                                                center={true}
                                                onCloseClick={() => { setShowOptions(false); }}>
                                                {!isLoading && orgs && orgs.length > 0 ?
                                                    orgs.map((item, i) => {
                                                        return (<button className="flex items-center" onClick={async (e) => { e.preventDefault(); selectOrg(item); }}>{item.name}</button>)
                                                    }) :
                                                    <span>No Organizations were found.</span>
                                                }
                                                {isLoading && <LoadingSkeleton />}
                                            </Menu>
                                        }
                                    </>
                                    <div className='mt-12'></div>
                                </> :
                                <>
                                    <InputText
                                        bottomMargin={3}
                                        title={'Organization'}
                                        value={selectedOrg.name}
                                        disabled={true}
                                        onClear={resetOrg} />
                                    <div className='mb-3'>
                                        <DropdownSelector
                                            title={'Parking Area'}
                                            options={selectedOrg.parkingAreas.map((item, i) => { return { title: item.name, value: item.parkingAreaExtId } })}
                                            onChange={setSelectedParkingArea} />
                                    </div>
                                    <InputText
                                        bottomMargin={3}
                                        title={'Description'}
                                        value={description}
                                        onChange={setDescription}
                                        required={true} />
                                    <InputNumber
                                        className={'mb-3'}
                                        title={'Latitude'}
                                        value={latitude}
                                        onChange={setLatitude}
                                        required={true} />
                                    <InputNumber
                                        className={'mb-3'}
                                        title={'Longitude'}
                                        value={longitude}
                                        onChange={setLongitude}
                                        required={true} />
                                    <ButtonText className="h-6 flex justify-center items-center" disabled={!canCreate} isLoading={isLoading} onClick={(e) => { e.preventDefault(); handleCreateAirPass(); }}>
                                        Create AirPass
                                    </ButtonText>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>);
    }
};

export default NewAirPassModal;