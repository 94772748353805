import React, { useEffect } from 'react';
import ButtonText from 'components/common/buttonText';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'components/icons/icons';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import { useLocation } from 'react-router-dom';

const BetweenPagesMessage = ({ title, description, onClick, display, setDisplay, template}) => {
    const location = useLocation();

    useEffect(() => {
        setDisplay(false);
    }, [location]);

    if (!display) {
        return (null);
    }

    return (
        <div className='flex fixed inset-0 bg-white'>
            <div className="flex p-6 flex-col absolute h-full w-full items-center justify-center">
                <div className="flex flex-col justify-center items-center max-w-xs w-full">
                    {template == 'loading' &&
                        <>
                            <Lottie className="h-10 w-10" animationData={animSpinnerGreen} loop={true} />
                            <div className="flex h-10 items-center mt-2">
                                <h1 className="text-2xl font-medium">Loading</h1>
                            </div>
                        </>
                    }
                    {template == 'success' &&
                        <SuccessIcon className='w-10 h-10' />
                    }
                    {template == 'info' &&
                        <InfoIcon className='w-10 h-10' />
                    }
                    {template == 'warning' &&
                        <WarningIcon className='w-10 h-10' />
                    }
                    {template == 'error' &&
                        <ErrorIcon className='w-10 h-10' />
                    }
                    {template != 'loading' &&
                        <>
                            <div className="flex flex-col mt-4 justify-center">
                                <h2 className="text-2xl mt-4 text-center">{title}</h2>
                                <p className="mt-2 text-center">{description}</p>
                            </div>
                            <div className="flex w-full justify-end mt-4">
                                <ButtonText onClick={(e) => { onClick(e); }} className='w-full'>
                                    OK
                                </ButtonText>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default BetweenPagesMessage;